import React, { Component } from 'react';
import Firebase from 'firebase';
import Moment from 'moment';
import 'firebase/functions';
import Connector from '../../data/Connector';
import HelperCal from '../../helper/Calendar';
import HelperClients from '../../helper/Clients';
import HelperBusiness from '../../helper/Business';
import HelperEmptyImg from '../../helper/EmptyLarge';
import Message from '../../components/Message';
import Textarea from '../../components/Form/textarea';
import InputToggle from '../../components/Form/toggle';
import Navigation from '../../components/Navigation';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class ActiveSessionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      item: null,
      program: null,
      view: 'select',
      clients: global.clients,
      client: null,
      back: ROUTES.CALENDAR,
      hrs: [],
      min: [],
      link: '',
      current: 0,
      results: 0,
      round: 1,
      interval: 0,
      intmax: 0,
      timer: 'getready',
      end: 0,
      pos: global.timerStart,
      counter: null,
      volume: true,
      // Results
      valAR1: [],
      valAR2: [],
      notes: '',
      notesSimple: [],
      valueSimple: [],
      scaledSimple: [],
      ex: [],
      // Modals
      margin: 0,
      showing: 0,
      showModalPause: false,
      hiddenPause: 'hidden',
      paused: false,
      showModalEnergy: false,
      hiddenEnergy: 'hidden',
      showModalAbort: false,
      hiddenAbort: 'hidden',
      showModalNotes: false,
      hiddenNotes: 'hidden',
      energy: [],
      levels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      energyShown: false,
      best: null,
      bestGroup: null
    };
  }


  componentDidMount() {
    Connector.setUser()
    document.title = 'Run a Session - PT Mate'
    window.Intercom("update")
    window.scrollTo(0, 0)

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('sessionsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('tokenLoaded', (event) => this.setState({token: global.clientToken}))
    var tmp1 = []
    //var tmp2 = []
    //var tmp3 = []
    for(var i=0; i<60; i++) {
      if(i<10) {
        tmp1.push('0'+i)
      } else {
        tmp1.push(i)
      }
    }
    this.setState({
      min: tmp1,
      sec: tmp1,
    })
    var audio1 = new Audio("/audio/audio1sec.mp3")
    audio1.preload = "auto"
    var audio2 = new Audio("/audio/audiostart.mp3")
    audio2.preload = "auto"
    var audio3 = new Audio("/audio/audioend.mp3")
    audio3.preload = "auto"
    this.configureData()
  }


  configureData() {
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-1]
    var back = ROUTES.CALENDAR
    var item = null
    var program = null

    back = '/calendar/group-session/'+id
    if(global.linkCli !== '') {
      back = global.linkCli
    }

    for(var session of global.sessions) {
      if(session.id === id) {
        item = session
        program = session.program[0]
        if(!session.data.group) {
          back = '/calendar/session/'+id
        }
      }
    }

    if(item !== null) {
      var user = ''
      for(var client of global.clients) {
        if(client.id === item.data.client) {
          user = client.id
          if(client.data.uid !== '') {
            user = client.data.uid
          }
          Connector.loadClientBest(user)
          setTimeout(() => {
            this.setState({
              best: global.clientBest
            })
          }, 3500);
        }
      }

      if(item.data.group) {
        global.clientBestGroup = []
        for(var client2 of item.data.clients) {
          for(var cl of global.clients) {
            if(cl.id === client2 && cl.data.uid !== '') {
              Connector.loadClientBestGroup(cl.data.uid)
            }
            if(cl.id === client2 && cl.data.uid === '') {
              Connector.loadClientBestGroup(cl.id)
            }
          }
        }
        setTimeout(() => {
          this.setState({
            bestGroup: global.clientBestGroup
          })
        }, 3500);
      }
    }

    this.setState({
      id: id,
      clients: global.clients,
      clientsList: global.clients,
      back: back,
      item: item,
      program: program
    }, () => {
      if(this.state.current === 0 && this.state.item !== null && !this.state.energyShown) {
        this.setState({
          energyShown: true
        })
        setTimeout(() => {
          this.showModalEnergy()
        }, 500);
      }
    })
  }



  // Configuration ------------------------------------------------------------



  getSessionName() {
    var label = 'Session'
    var date = ''
    if(this.state.item !== null) {
      if(this.state.item.data.group && this.state.item.data.client === '') {
        label = 'Class'
      } else if(this.state.item.data.group && this.state.item.data.client !== '') {
        label = this.state.item.data.client
      } else {
        label = this.getClientName(this.state.item.data.client, 'first')+' '+this.getClientName(this.state.item.data.client, 'last')
      }
      date = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').format('hh:mm a')
      //label += Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').format('hh:mm a')
    }
    return <div><h3>{label}</h3><h2>{date}</h2></div>
  }


  getSetsName(item) {
    var label = 'set' 
    if(item.exId.length > 1) {
      var prev = ''
      var multiple = true
      for(var ex of item.exId) {
        if(ex !== prev && prev !== '') {
          label = 'superset'
          multiple = false
        }
        if(ex === prev && prev !== '') {
          label = 'dropset'
        }
        prev = ex
      }
      if(multiple) {
        label = 'dropset'
      } else {
        label = 'superset'
      }
    }
    return label
  }


  getBlockHeader(block) {
    if(this.state.program !== null) { 
      var item = block.data
      var name = lang.t(global.proTypes[item.type])
      var label = ''
      var s = 's'
      if((item.rounds === 1 && item.type !== 5) || (item.cycles === 1 && item.type === 5)) {
        s = ''
      }
      if(item.type === 0) {
        label = ' - '+HelperCal.getDurationMin(item.rounds)
      }
      if(item.type === 1) {
        label = ' - '+item.rounds+' round'+s
        
        if(item.emom) {
          label = ' - '+item.rounds+' round'+s+' of '+HelperCal.getDurationMinAlt(parseInt(item.exWork[0]))
        }
      }
      if(item.type === 2 || item.type === 3) {
        label = ' - '+item.rounds+' round'+s
      }
      if(item.type === 4) {
        label = ' - '+item.rounds+' '+this.getSetsName(item)+'s'
        if(item.rounds === 1) {
          label = ' - 1 '+this.getSetsName(item)
        }
      }
      if(item.type === 5) {
        label = ' - '+HelperCal.getDurationMin(item.rounds)
        if(item.cycles > 1) {
          label = ' - '+item.cycles+' round'+s+' - '+HelperCal.getDurationMin(item.rounds)
        }
      }
      return name+' '+label
    }
  }


  getInterval(block, index) {
    if(!block.data.emom && (block.data.type === 1 || block.data.type === 2)) {
      if(block.data.exRest[index] !== 0) {
        return (
          <span><br/>{HelperCal.getDurationMin(block.data.exWork[index])} work - {HelperCal.getDurationMin(block.data.exRest[index])} rest</span>
        )
      } else {
        return (
          <span><br/>{HelperCal.getDurationMin(block.data.exWork[index])}</span>
        )
      }
      
    }
  }


  getClient(id) {
    var client = null
    for(var item of this.state.clients) {
      if(item.id === id) {
        client = item
      }
    }
    return client
  }


  getClientName(id, type) {
    var label = ''
    for(var item of this.state.clients) {
      if(item.id === id) {
        var ar = item.data.name.split(' ')
        if(type === 'first') {
          label = ar[0]
        } else {
          if(ar.length > 1) {
            for(var i=1; i<ar.length; i++) {
              label += ar[i]+' '
            }
          }
        }
      }
    }
    return label
  }


  getExInfo(block, index) {
    var label = '-'
    var weight = ''
    var unit1 = 'reps'
    var word = ' with '
    var unit2 = block.data.exWeightType[index]
    if(block.data.exWeightType[index] === 'per' || block.data.exWeightType === undefined) {
      unit2 = (global.spaceLbs ? 'lb' : 'kg')
    }
    if(block.data.exUnits !== undefined) {
      if(block.data.exUnits[index] === 'dist') {
        unit1 = 'm'
      }
      if(block.data.exUnits[index] === 'cals') {
        unit1 = 'cal'
      }
      if(block.data.exUnits[index] === 'time') {
        unit1 = 's'
      }
    } else {
      if(block.data.exTool[index] === 6 || block.data.exTool[index] === 7) {
        unit1 = 'm'
      }
      if(block.data.exTool[index] === 27) {
        unit1 = 'cal'
      }
    }
    if(block.data.exWeightType !== undefined) {
      if(block.data.exWeightType[index] === 'per') {
        word = ' at '
        unit2 = '%'
      }
    }
    if(block.data.exReps[index] !== 0) {
      label = block.data.exReps[index]+' '+unit1
    }
    if(block.data.exRepsRounds !== undefined) {
      if(block.data.exRepsRounds[index] !== '0' && block.data.exRepsRounds[index] !== '') {
        label = block.data.exRepsRounds[index]+' '+unit1
      }
      
    }
    if(block.data.exWeight[index] !== 0) {
      weight = word+block.data.exWeight[index]+' '+unit2
      if(block.data.exWeightType[index] === 'kg') {
        weight = word+block.data.exWeight[index]+' '+unit2
      }
    }
    if(block.data.exWeightRounds !== undefined) {
      if(block.data.exWeightRounds[index] !== '0.0' && block.data.exWeightRounds[index] !== '' && block.data.exWeightRounds[index] !== '0') {
        weight = word+block.data.exWeightRounds[index]+' '+unit2
        if(block.data.exWeightType[index] === 'kg') {
          weight = word+block.data.exWeightRounds[index]+' '+unit2
        }
      }
    }
    return label+weight
  }


  getTimerName() {
    var label = 'Get ready'
    if(this.state.timer === 'work') {
      label = 'Work'
      if(this.state.program.blocks[this.state.current].data.type === 3) {
        label = 'Work '+(this.state.interval+1)+' of 8'
      }
    } else if(this.state.timer === 'rest') {
      label = 'Rest'
      if(this.state.program.blocks[this.state.current].data.type === 3) {
        label = 'Rest '+(this.state.interval+1)+' of 8'
      }
    }
    return label
  }


  displayTimer() {
    var label = this.state.pos
    if(this.state.timer !== 'getready') {
      var min = Math.floor(this.state.pos/60)
      var sec = this.state.pos-min*60
      if(min < 10) {
        min = '0'+min
      }
      if(sec < 10) {
        sec = '0'+sec
      }
      label = min+':'+sec
    }
    return label
  }



  // Timer control ------------------------------------------------------------



  startTimer() {
    if(this.state.program.blocks[this.state.current].data.type !== 4) {
      this.setState({
        view: 'timer',
        timer: 'getready',
        end: 0,
        pos: global.timerStart,
        round: 1,
        interval: 0,
      })
      this.counter = setInterval(this.count.bind(this), 1000);
    } else {
      this.setState({
        view: 'sets',
        timer: 'rest',
        round: 1,
        end: 0,
        pos: global.timerStart,
      })
    }
    
  }


  configureTimer() {
    var block = this.state.program.blocks[this.state.current]
    var start = 0
    var end = 0
    var intmax = 0
    if(block.data.type === 0) {
      start = block.data.rounds
    } else if(block.data.type === 1) {
      start = block.data.exWork[this.state.interval]
    } else if(block.data.type === 2) {
      start = block.data.exWork[this.state.interval]
      if(block.data.emom) {
        intmax = 1
      } else {
        intmax = block.data.exName.length
      }
    } else if(block.data.type === 3) {
      start = block.data.exWork[this.state.interval]
      intmax = 8
    } else if(block.data.type === 5) {
      start = 0
      end = block.data.rounds
    }
    this.setState({
      timer: 'work',
      pos: start,
      end: end,
      intmax: intmax
    })
    this.counter = setInterval(this.count.bind(this), 1000);
  }


  count() {
    var tmp = this.state.pos
    var block = this.state.program.blocks[this.state.current].data
    if(block.type === 5 && this.state.timer === 'work') {
      tmp++
    } else {
      tmp--
    }
    if(this.state.showModalPause) {
      clearInterval(this.counter)
    }
    
    this.setState({
      pos: tmp
    })
    if(tmp > 0 && tmp < 4 && this.state.timer === 'getready') {
      var audio1 = new Audio("/audio/audio1sec.mp3")
      if(!this.state.volume) {
        audio1.volume = 0
      }
      audio1.play()
    }

    if(tmp === this.state.end-1 || tmp === this.state.end+1) {
      setTimeout(() => {
        var audio = new Audio("/audio/audioend.mp3")
        if(this.state.timer === 'getready') {
          audio = new Audio("/audio/audiostart.mp3")
        }
        if(!this.state.volume) {
          audio.volume = 0
        }
        audio.play()
      }, 950);
      
    }

    if(tmp === this.state.end) {
      clearInterval(this.counter)
      setTimeout(() => {
        if(this.state.timer === 'getready') {
          this.configureTimer()
        } else {
          // AMRAP & For Time
          if(block.type === 0 || block.type === 5) {
            this.endTimer()
          // EMOM
          } else if(block.type === 1) {
            if(this.state.round === block.rounds && (this.state.interval === block.exWork.length-1 || block.emom)) {
              this.endTimer()
            } else {
              if((this.state.interval === block.exWork.length-1 || block.emom) && this.state.round <= block.rounds) {
                var tmp = this.state.round
                tmp++
                this.setState({
                  pos: block.exWork[0],
                  end: 0,
                  interval: 0,
                  round: tmp
                })
                this.counter = setInterval(this.count.bind(this), 1000);
              } else if(this.state.interval < block.exWork.length-1 && !block.emom && this.state.round <= block.rounds) {
                var tmp2 = this.state.interval
                tmp2++
                this.setState({
                  pos: block.exWork[tmp2],
                  end: 0,
                  interval: tmp2,
                })
                this.counter = setInterval(this.count.bind(this), 1000);
              }
            }
          // Intervals & Tabata
          } else if(block.type === 2 || block.type === 3) {
            this.configureInterval()
          } else if(block.type === 4) {
            this.setState({
              view: 'sets',
              timer: 'rest',
            })
          }
        }
      }, 1000);
      
    }
  }


  configureInterval() {
    var block = this.state.program.blocks[this.state.current].data
    if(this.state.timer === 'work') {
      var pos = block.exRest[this.state.interval]
      if(block.type === 3) {
        pos = 10
      }
      this.setState({
        pos: pos,
        end: 0,
        timer: 'rest'
      })
      if(pos === 0) {
        this.configureInterval()
      } else {
        this.counter = setInterval(this.count.bind(this), 1000);
      }
    } else {
      if(this.state.interval < this.state.intmax-1) {
        var tmp = this.state.interval
        tmp++
        var pos2 = block.exWork[tmp]
        if(block.type === 3) {
          pos2 = 20
        }
        this.setState({
          pos: pos2,
          end: 0,
          timer: 'work',
          interval: tmp
        })
        this.counter = setInterval(this.count.bind(this), 1000);
      } else {
        var tmp3 = this.state.round
        if(this.state.round < block.rounds) {
          tmp3++
          this.setState({
            pos: block.exWork[0],
            end: 0,
            timer: 'work',
            interval: 0,
            round: tmp3
          })
          this.counter = setInterval(this.count.bind(this), 1000);
        } else {
          this.endTimer()
        }
      }
    }
  }


  endTimer() {
    var tmp = this.state.current
    tmp++
    this.setState({
      view: 'select',
      timer: 'getready',
      current: tmp,
      showModalNotes: false
    })
  }


  abortTimer() {
    clearInterval(this.counter)
    this.endTimer()
  }


  toggleAudio() {
    var tmp = true
    if(this.state.volume === true) {
      tmp = false
    }
    this.setState({
      volume: tmp
    })
  }



  // Sets & Results ------------------------------------------------------------



  finishSet() {
    var block = this.state.program.blocks[this.state.current].data
    if(block.rounds > 1 && this.state.round < block.rounds) {
      var tmp = this.state.round
      tmp++
      if(block.exWork[0] === 0) {
        this.setState({
          round: tmp
        })
      } else {
        this.setState({
          pos: block.exWork[0],
          end: 0,
          timer: 'rest',
          view: 'timer',
          round: tmp
        })
        this.counter = setInterval(this.count.bind(this), 1000);
      }
    } else {
      this.endTimer()
    }
  }


  showResults(index) {
    var block = this.state.program.blocks[index].data
    var tmp3 = []
    var tmp4 = []
    var notesSimple = []
    var ex = []

    var valueSimple = []
    var scaledSimple = []

    // Standard results
    if(!block.simple || block.simple === undefined) {
      for(var i=0; i<block.exId.length; i++) {

        var e = {
          name: block.exName[i],
          tool: block.exTool[i],
          base: block.exReps[i],
          reps: [],
          weight: [],
          repsr: [],
          weightr: [],
        }

        // Group session
        if(this.state.item.data.group) {
          for(var j=0; j<this.state.item.data.clients.length; j++) {
            // Reps
            if(block.exResRepsGroup[i] !== undefined && block.exResRepsGroup[i] !== '') {
              var a1 = block.exResRepsGroup[i].split('-')
              if(a1.length > j) {
                if(a1[j] !== '') {
                  e.reps.push(parseInt(a1[j]))
                } else {
                  e.reps.push(0)
                }
              } else {
                e.reps.push(0)
              }
            } else {
              e.reps.push(0)
            }
            if(block.exResRepsRounds !== undefined) {
              if(block.exResRepsRounds.length > i) {
                var a11 = block.exResRepsRounds[i].split('|')
                if(a11.length > j) {
                  if(a11[j] !== '') {
                    e.repsr.push(String(a11[j]))
                  } else {
                    e.repsr.push(String('0'))
                  }
                } else {
                  e.repsr.push(String('0'))
                }
              } else {
                e.repsr.push(String('0'))
              }
            } else {
              e.repsr.push('0')
            }
            // Weight
            if(block.exResWeightGroup[i] !== undefined && block.exResWeightGroup[i] !== '') {
              var a2 = block.exResWeightGroup[i].split('-')
              if(a2.length > j) {
                if(a2[j] !== '') {
                  e.weight.push(parseFloat(a2[j]))
                } else {
                  e.weight.push(String(0))
                }
              } else {
                e.weight.push(String(0))
              }
            } else {
              e.weight.push(String(0))
            }
            if(block.exResWeightRounds !== undefined) {
              if(block.exResWeightRounds.length > i) {
                var a21 = block.exResWeightRounds[i].split('|')
                if(a21.length > j) {
                  if(a21[j] !== '') {
                    e.weightr.push(String(a21[j]))
                  } else {
                    e.weightr.push(String('0'))
                  }
                } else {
                  e.weightr.push(String('0'))
                }
              } else {
                e.weightr.push(String('0'))
              }
            } else {
              e.weightr.push('0')
            }
          }

        // 1:1 session
        } else {
          if(block.exResReps[i] !== undefined && block.exResReps[i] !== '') {
            e.reps.push(block.exResReps[i])
          } else {
            e.reps.push(0)
          }
          if(block.exResRepsRounds !== undefined) {
            if(block.exResWeightRounds.length > i) {
              if(block.exResRepsRounds[i] !== undefined && block.exResRepsRounds[i] !== '') {
                e.repsr.push(block.exResRepsRounds[i])
              } else {
                e.repsr.push('0')
              }
            } else {
              e.repsr.push('0')
            }
          } else {
            e.repsr.push('0')
          }
          if(block.exResWeight[i] !== undefined && block.exResWeight[i] !== '') {
            e.weight.push(block.exResWeight[i])
          } else {
            e.weight.push(0)
          }
          if(block.exResWeightRounds !== undefined) {
            if(block.exResWeightRounds.length > i) {
              if(block.exResWeightRounds[i] !== undefined && block.exResWeightRounds[i] !== '') {
                e.weightr.push(block.exResWeightRounds[i])
              } else {
                e.weightr.push('0')
              }
            } else {
              e.weightr.push('0')
            }
          } else {
            e.weightr.push('0')
          }
        }
        ex.push(e)
      }
    // Simple results
    } else {
      if(this.state.item.data.group) {
        for(var j2=0; j2<this.state.item.data.clients.length; j2++) {
          if(block.valueSimple !== undefined) {
            if(block.valueSimple.length > j2) {
              valueSimple.push(block.valueSimple[j2])
            } else {
              valueSimple.push(0)
            }
          } else {
            valueSimple.push(0)
          }
          if(block.scaledSimple !== undefined) {
            if(block.scaledSimple.length > j2) {
              scaledSimple.push(block.scaledSimple[j2])
            } else {
              scaledSimple.push(false)
            }
          } else {
            scaledSimple.push(false)
          }
        }
      } else {
        if(block.valueSimple !== undefined) {
          valueSimple = block.valueSimple[0]
        } else {
          valueSimple = [0]
        }
        if(block.scaledSimple !== undefined) {
          scaledSimple = block.scaledSimple[0]
        } else {
          scaledSimple = [false]
        }
      }
    }

    if(block.type === 0) {
      // Standard results
      if(!block.simple || block.simple === undefined) {
        var amrap = []
        var div = 0
        if(this.state.item.data.group) {
          for(var cl of this.state.item.data.clients) {
            amrap.push(0)
          }
          for(var e1 of ex) {
            div += e1.base
            for(var i1=0; i1<this.state.item.data.clients.length; i1++) {
              amrap[i] += parseInt(e1.reps[i])
            }
          }
          for(var am of amrap) {
            var t1 = Math.floor(am/div)
            var t2 = am-(t1*div)
            tmp3.push(t1)
            tmp4.push(t2)
          }
        } else {
          amrap = [0]
          for(var e2 of ex) {
            div += e2.base
            amrap[0] += (parseInt(e2.reps[0]))
          }
          var t11 = Math.floor(amrap[0]/div)
          var t21 = amrap[0]-(t11*div)
          tmp3.push(t11)
          tmp4.push(t21)
        }
      // Simple results
      } else {
        tmp3 = []
        tmp4 = []
        var aram = []
        if(this.state.item.data.group) {
          if(block.amrapSimple !== undefined) {
            for(var j21=0; j21<this.state.item.data.clients.length; j21++) {
              if(block.amrapSimple.length > j21) {
                aram = block.amrapSimple[j21].split('+')
                tmp3.push(aram[0])
                tmp4.push(aram[1])
              } else {
                tmp3.push('0')
                tmp4.push('0')
              }
            }
          } else {
            for(var j22=0; j22<this.state.item.data.clients.length; j22++) {
              tmp3.push('0')
              tmp4.push('0')
            }
          }
        } else {
          if(block.amrapSimple !== undefined) {
            aram = block.amrapSimple[0].split('+')
            tmp3.push(aram[0])
            tmp4.push(aram[1])
          } else {
            tmp3.push('0')
            tmp4.push('0')
          }
        }
      }
    }

    if(block.type === 5) {
      tmp3 = []
      tmp4 = []
      if(this.state.item.data.group) {
        if(block.timeResGroup !== undefined) {
          for(var i2=0; i2<this.state.item.data.clients.length; i2++) {
            if(block.timeResGroup.length > i2) {
              var min = parseInt(Math.floor(block.timeResGroup[i2]/60))
              var sec = block.timeResGroup[i2]-min*60
              tmp3.push(min)
              tmp4.push(sec)
            } else {
              tmp3.push(0)
              tmp4.push(0)
            }
          }
          
        } else {
          for(var i3=0; i3<this.state.item.data.clients.length; i3++) {
            tmp3.push(0)
            tmp4.push(0)
          }
        }
      } else {
        if(block.timeRes !== undefined) {
          var min1 = parseInt(Math.floor(block.timeRes/60))
          var sec1 = block.timeRes-min1*60
          tmp3.push(min1)
          tmp4.push(sec1)
        } else {
          tmp3.push(0)
          tmp4.push(0)
        }
      }
    }

    if(this.state.item.data.group) {
      if(block.notesResSimple !== undefined && block.notesResSimple !== '') {
        var arn = block.notesResSimple.split('|')
        for(var i5=0; i5<this.state.item.data.clients.length; i5++) {
          if(arn.length > i5) {
            notesSimple.push(arn[i5])
          } else {
            notesSimple.push('')
          }
        }
      } else {
        for(var i5=0; i5<this.state.item.data.clients.length; i5++) {
          notesSimple.push('')
        }
      }
    } else {
      if(block.notesResSimple !== undefined && block.notesResSimple !== '') {
        notesSimple.push(block.notesResSimple)
      } else {
        notesSimple.push('')
      }
    }

    this.setState({
      view: 'results',
      results: index,
      ex: ex,
      valAR1: tmp3,
      valAR2: tmp4,
      notes: block.notesRes,
      notesSimple: notesSimple,
      valueSimple: valueSimple,
      scaledSimple: scaledSimple
    })
  }


  hideResults() {
    this.setState({
      view: 'select'
    })
  }



  // Pause modal ------------------------------------------------------------



  showModalPause() {
    this.setState({
      showModalPause: true,
      paused: true
    })
    clearInterval(this.counter)
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenPause: '',
        margin: -height/2
      });
    }, 100);
  }


  hideModalPause() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenPause: 'hidden',
      paused: false,
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showModalPause: false});
    }, 500);
  }


  renderModalPause() {
    if(this.state.showModalPause) {
      return (
        <div className={'overlay '+this.state.hiddenPause}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{lang.t('modals:pause.title')}</h2>
            <p className="clear mb-30">{lang.t('modals:pause.text')}</p>
            <button className="btn primary" onClick={() => {this.hideModalPause(); clearInterval(this.counter); this.counter = setInterval(this.count.bind(this), 1000);}}>{lang.t('modals:pause.button')}</button>
            <div className="sv-20"></div>
            <button className="btn tertiary width-12" onClick={() => {this.hideModalPause(); this.startTimer()}}>{lang.t('modals:pause.button.restart')}</button>
          </div>
        </div>
      )
    }
  }



  // Start and end session ------------------------------------------------------------



  endSession() {
    if(this.state.current < this.state.program.blocks.length) {
      this.showModalAbort()
    } else {
      this.showModalEnergy()
    }
  }


  showModalAbort() {
    this.setState({
      showModalAbort: true,
      paused: true
    })
    clearInterval(this.counter)
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenAbort: '',
        margin: -height/2
      });
    }, 100);
  }


  hideModalAbort() {
    const height = this.divElement.clientHeight
    if(this.state.status === 'running' || this.state.status === 'getready') {
      this.countTimer(this.state.time)
    }
    this.setState({
      hiddenAbort: 'hidden',
      paused: false,
      margin: -height/2+100
    })
    setTimeout(() => {
      if(this.state.view === 'timer') {
        this.counter = setInterval(this.count.bind(this), 1000);
      }
      
      this.setState({showModalAbort: false});
    }, 500);
  }


  renderModalAbort() {
    if(this.state.showModalAbort) {
      return (
        <div className={'overlay '+this.state.hiddenAbort}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{lang.t('modals:abort.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => {this.hideModalAbort()}}>{lang.t('common:button.cancel')}</button>
            <p className="mb-30 clear">{lang.t('modals:abort.text')}</p>
            <Link to={this.state.back} className="btn primary">{lang.t('modals:abort.button')}</Link>
          </div>
        </div>
      )
    }
  }


  showModalEnergy() {
    var energy = []
    if(this.state.item.data.group) {
      for(var item of this.state.item.data.clients) {
        energy.push(0)
      }
    } else {
      energy.push(0)
    }
    this.setState({
      showModalEnergy: true,
      energyShown: true
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenEnergy: '',
        margin: -height/2,
        energy: energy,
      });
    }, 100);
  }


  hideModalEnergy() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenEnergy: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showModalEnergy: false});
    }, 500);
  }


  getClientNameEnergy(id) {
    var label = lang.t('common:label.deletedclient')
    for(var item of this.state.clients) {
      if(item.id === id) {
        label = item.data.name
      }
    }
    return label
  }


  renderEnergyGroup() {
    return (
      <div>
        {this.state.item.data.clients.map((item, ind) => (
          <div key={item}>
            <label>{this.getClientNameEnergy(item)}</label>
            <div className="radios">
              {this.state.levels.map((item, index) => (
                <button className={this.state.energy[ind] === item ? 'radio col active' : 'radio col'} style={{float: 'left', width: '10%'}} key={item} onClick={() => this.setEnergy(item, ind)}>{item}</button>
              ))}
            </div>
            <div className="sv-30"></div>
          </div>
        ))}
      </div>
    )
  }


  renderModalEnergy() {
    if(this.state.showModalEnergy) {
      if(this.state.item.data.group) {
        return (
          <div className={'overlay '+this.state.hiddenEnergy}>
            <div className="box clients" ref={ (divElement) => { this.divElement = divElement }}>
              <h2 className="mb-20 lft">{lang.t('modals:energy.title')}</h2>
              <button className="btn tertiary small close rgt" onClick={() => this.skipEnergy()}>{lang.t('modals:energy.button.skip')}</button>
              <div className="scroll clear">
                <p className="mb-30">{this.state.current === 0 ? lang.t('modals:energy.text') : lang.t('modals:energy.text.end')}</p>
                {this.renderEnergyGroup()}
              </div>
              <button className="btn primary" onClick={() => this.saveEnergy()}>{this.state.current === 0 ? lang.t('modals:energy.button') : lang.t('modals:energy.button.end')}</button>
            </div>
          </div>
        )
      } else {
        return (
          <div className={'overlay '+this.state.hiddenEnergy}>
            <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{lang.t('modals:energy.title')}</h2>
              <button className="btn tertiary small close rgt" onClick={() => this.skipEnergy()}>{lang.t('modals:energy.button.skip')}</button>
              <p className="clear mb-20">{this.state.current === 0 ? lang.t('modals:energy.text') : lang.t('modals:energy.text.end')}</p>
              <div className="radios">
                {this.state.levels.map((item, index) => (
                  <button className={this.state.energy[0] === item ? 'radio col active' : 'radio col'} style={{float: 'left', width: '10%'}} key={item} onClick={() => this.setEnergy(item, 0)}>{item}</button>
                ))}
              </div>
              <div className="sv-30"></div>
              <button className="btn primary" onClick={() => this.saveEnergy()}>{this.state.current === 0 ? lang.t('modals:energy.button') : lang.t('modals:energy.button.end')}</button>
            </div>
          </div>
        )
      }
    }
  }


  setEnergy(value, index) {
    var tmp = this.state.energy
    tmp[index] = value
    this.setState({
      energy: tmp
    })
  }


  skipEnergy() {
    this.hideModalEnergy()
    if(this.state.current !== 0) {
      this.props.history.push(this.state.back)
    }
  }


  saveEnergy() {
    var label = ''
    for(var item of this.state.energy) {
      label += '-'+item
    }
    if(this.state.current === 0) {
      Firebase.database().ref('/sessions/'+global.uid+'/'+this.state.id).update({
        energyStart: label,
      }).then((data)=>{
        this.hideModalEnergy()
        EventEmitter.dispatch('showMessage', lang.t('messaging:message.clientenergysaved'));
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    } else {
      Firebase.database().ref('/sessions/'+global.uid+'/'+this.state.id).update({
        energyEnd: label,
      }).then((data)=>{
        this.hideModalEnergy()
        global.message = lang.t('messaging:message.clientenergysaved')
        this.props.history.push(this.state.back)
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    }
    
  }


  showModalNotes() {
    this.setState({
      showModalNotes: true,
    })
  }


  hideModalNotes() {
    this.setState({
      showModalNotes: false
    })
  }


  renderModalNotes() {
    if(this.state.showModalNotes) {
      return (
        <div className="run-onotes">
          <h2 className="mb-20 lft">{lang.t('modals:payment.notes')}</h2>
          <button className="btn tertiary small close rgt" onClick={() => {this.hideModalNotes()}}>{lang.t('common:button.close')}</button>
          <p className="clear" style={{whiteSpace: 'pre-wrap'}}>{this.state.program.blocks[this.state.current].data.notes}</p>
          <div className="clear sv-20"></div>
        </div>
      )
    }
  }



  // Results stuff ------------------------------------------------------------



  renderSimpleValue(client) {
    if(this.state.program.blocks[this.state.results].data.type !== 0 && this.state.program.blocks[this.state.results].data.type !== 5) {
      return (
        <div className="item">
          <p className="title"><strong>Rounds and reps</strong></p>
          <div className="first">
            <p>Result ({(this.state.program.blocks[this.state.results].data.unitSimple === undefined || this.state.program.blocks[this.state.results].data.unitSimple === '') ? 'reps' : this.state.program.blocks[this.state.results].data.unitSimple})</p>
            <input type="number" value={this.state.valueSimple[client]} onChange={(val) => this.onChangeSimpleValue(val, client)}/>
          </div>
          <div className="clear"></div>
        </div>
      )
    }
  }


  renderExResult(client) {
    if(this.state.program.blocks[this.state.results].data.simple) {
      return (
        <div>
          {this.renderAmrap(client)}
          {this.renderTime(client)}
          {this.renderSimpleValue(client)}
        </div>
      )
    } else {
      return (
        <div>
          {this.renderAmrap(client)}
          {this.renderTime(client)}
          {this.state.program.blocks[this.state.results].data.exId.map((item, index) => (
            <div key={index}>
              {this.renderExFields(index, client)}
            </div>
          ))}
        </div>
      )
    }
  }


  renderAmrap(client) {
    if(this.state.program.blocks[this.state.results].data.type === 0) {
      return (
        <div className="item">
          <p className="title"><strong>Rounds and reps</strong></p>
          <div className="first">
            <p>Rounds</p>
            <input type="number" value={this.state.valAR1[client]} onChange={(val) => this.onChangeRoundsAR(val, client)}/>
          </div>
          <div className="second">
            <p>Reps</p>
            <input type="number" value={this.state.valAR2[client]} onChange={(val) => this.onChangeRepsAR(val, client)}/>
          </div>
          <div className="clear"></div>
        </div>
      )
    }
  }


  renderTime(client) {
    if(this.state.program.blocks[this.state.results].data.type === 5) {
      return (
        <div className="item">
          <p className="title"><strong>Completion time</strong></p>
          <div className="first">
            <p>Minutes</p>
            <input type="number" value={this.state.valAR1[client]} onChange={(val) => this.onChangeMin(val, client)}/>
          </div>
          <div className="second">
            <p>Seconds</p>
            <input type="number" value={this.state.valAR2[client]} onChange={(val) => this.onChangeSec(val, client)}/>
          </div>
          <div className="clear"></div>
        </div>
      )
    }
  }


  getFieldPlaceholder(index, type) {
    var label = ''
    var block = this.state.program.blocks[this.state.results]
    var cycles = 1
    if(block.data.cycles !== undefined) {
      cycles = block.data.cycles
    }
    if(((block.data.type === 1 || block.data.type === 4) && block.data.rounds > 1) || (block.data.type === 5 && cycles > 1)) {
      if(type === 'reps') {
        label = 'E.g. 21-15-9'
      } else {
        label = 'E.g. 10-15-20'
      }
    }
    return label
  }


  renderExRepsTitle(block, index) {
    var label = 'Total reps'
    if(block.exUnits !== undefined) {
      if(block.exUnits.length > index) {
        if(block.exUnits[index] === 'dist') {
          label = 'Total distance (m)'
        }
        if(block.exUnits[index] === 'cals') {
          label = 'Total calories'
        }
        if(block.exUnits[index] === 'time') {
          label = 'Total time (s)'
        }
      }
    } else {
      if(block.exTool[index] === 6 || block.exTool[index] === 7 || block.exTool[index] === 25) {
        label = 'Total distance (m)'
      }
    }
    return label
  }


  renderExFields(index, client) {
    var block = this.state.program.blocks[this.state.results].data
    if(block.type === 0 || block.type === 5) {
      if(global.exToolsWeight.indexOf(block.exTool[index]) !== -1) {
        return (
          <div className="item">
            <p className="title"><strong>{block.exName[index]}</strong></p>
            <p>Weight used ({(block.exWeightType[index] !== 'per' ? block.exWeightType[index] : (global.spaceLbs ? 'lb' : 'kg'))})</p>
              <input type="text" value={this.state.ex[index].weightr[client]} onChange={(val) => this.onChangeWeight(val, index, client)} placeholder={this.getFieldPlaceholder(index, 'weight')}/>
          </div>
        )
      }
    } else {
      if(block.exTool[index] === 0 || block.exTool[index] === 6 || block.exTool[index] === 7 || block.exTool[index] === 9 || block.exTool[index] === 11 || block.exTool[index] === 25) {
        return (
          <div className="item">
            <p className="title"><strong>{block.exName[index]}</strong></p>
            <p>{this.renderExRepsTitle(block, index)}</p>
            <input type="text" value={this.state.ex[index].repsr[client]} onChange={(val) => this.onChangeReps(val, index, client)} placeholder={this.getFieldPlaceholder(index, 'reps')}/>
          </div>
        )
      } else {
        return (
          <div className="item">
            <p className="title"><strong>{block.exName[index]}</strong></p>
            <div className="first">
              <p>{this.renderExRepsTitle(block, index)}</p>
              <input type="text" value={this.state.ex[index].repsr[client]} onChange={(val) => this.onChangeReps(val, index, client)} placeholder={this.getFieldPlaceholder(index, 'reps')}/>
            </div>
            <div className="second">
              <p>Weight used ({(block.exWeightType[index] !== 'per' ? block.exWeightType[index] : (global.spaceLbs ? 'lb' : 'kg'))})</p>
              <input type="text" value={this.state.ex[index].weightr[client]} onChange={(val) => this.onChangeWeight(val, index, client)} placeholder={this.getFieldPlaceholder(index, 'weight')}/>
            </div>
            <div className="clear"></div>
          </div>
        )
      }
    }
  }


  onChangeReps(event, index, client) {
    var ex = this.state.ex
    var tval = event.target.value
    if(tval === '') {
      tval = '0'
    }
    var val1 = tval.replace(/[^0-9-]/g, "")
    var val = val1.replace("--", "-")

    if(val.indexOf('-') !== -1) {
      var arv = val.split('-')
      var lv = 0
      for(var av of arv) {
        if(av !== '') {
          lv += parseInt(av)
        }
      }
      ex[index].reps[client] = parseInt(lv)
    } else {
      ex[index].reps[client] = parseInt(val)
    }
    ex[index].repsr[client] = val
      
    this.setState({
      ex: ex
    })
  }


  onChangeWeight(event, index, client) {
    var ex = this.state.ex
    var tval = event.target.value
    if(tval === '') {
      tval = '0'
    }
    var val1 = tval.replace(/[^0-9-]/g, "")
    var val = val1.replace("--", "-")

    if(val.indexOf('-') !== -1) {
      var arv = val.split('-')
      var lv = 0
      for(var av of arv) {
        if(av !== '') {
          if(parseFloat(av) > lv) {
            lv = parseFloat(av)
          }
        }
      }
      ex[index].weight[client] = parseFloat(lv)
    } else {
      ex[index].weight[client] = parseFloat(val)
    }
    ex[index].weightr[client] = val

    this.setState({
      ex: ex
    })
  }


  onChangeRoundsAR(event, client) {
    var tmp = this.state.valAR1
    if(this.state.item.data.group) {
      tmp[client] = parseInt(event.target.value)
      if(event.target.value === '') {
        tmp[client] = 0
      }
      if(!this.state.program.blocks[this.state.results].data.simple || this.state.program.blocks[this.state.results].data.simple === undefined) {
        if(this.state.program.blocks[this.state.results].data.type === 0) {
          this.setAmrap(event.target.value, this.state.valAR1, client)
        }
      }
    } else {
      tmp[0] = parseInt(event.target.value)
      if(event.target.value === '') {
        tmp[0] = 0
      }
      if(!this.state.program.blocks[this.state.results].data.simple || this.state.program.blocks[this.state.results].data.simple === undefined) {
        if(this.state.program.blocks[this.state.results].data.type === 0) {
          this.setAmrap(event.target.value, this.state.valAR1, 0)
        }
      }
    }
    
    this.setState({
      valAR1: tmp
    })
  }


  onChangeRepsAR(event, client) {
    var tmp = this.state.valAR2
    if(this.state.item.data.group) {
      tmp[client] = parseInt(event.target.value)
      if(event.target.value === '') {
        tmp[client] = 0
      }
      if(!this.state.program.blocks[this.state.results].data.simple || this.state.program.blocks[this.state.results].data.simple === undefined) {
        if(this.state.program.blocks[this.state.results].data.type === 0) {
          this.setAmrap(this.state.valAR1, event.target.value, client)
        }
      }
    } else {
      tmp[0] = parseInt(event.target.value)
      if(event.target.value === '') {
        tmp[0] = 0
      }
      if(!this.state.program.blocks[this.state.results].data.simple || this.state.program.blocks[this.state.results].data.simple === undefined) {
        if(this.state.program.blocks[this.state.results].data.type === 0) {
          this.setAmrap(this.state.valAR1, event.target.value, 0)
        }
      }
    }
    this.setState({
      valAR2: tmp,
    })
  }


  setAmrap(rounds, rps, client) {
    var ex = this.state.ex
    var reps = parseInt(rps)
    var max = 0
    for(var e1 of this.state.ex) {
      max += e1.base
      e1.reps[client] = 0
    }
    var loop = Math.ceil(reps/max)
    if(loop > 0) {
      for(var i=0; i<loop; i++) {
        for(var e2 of ex) {
          if(reps > e2.base) {
            e2.reps[client] += e2.base
            reps -= e2.base
          } else {
            if(reps > 0) {
              e2.reps[client] += reps
              reps = 0
            }
          }
        }
      }
    }
    for(var e3 of this.state.ex) {
      e3.reps[client] += parseInt(rounds)*e3.base
    }
    this.setState({
      ex: ex,
    })
  }


  onChangeMin(event, client) {
    var tmp = this.state.valAR1
    if(this.state.item.data.group) {
      tmp[client] = parseInt(event.target.value)
      if(event.target.value === '') {
        tmp[client] = 0
      }
    } else {
      tmp[0] = parseInt(event.target.value)
      if(event.target.value === '') {
        tmp[0] = 0
      }
    }
    
    this.setState({
      valAR1: tmp
    })
  }


  onChangeSec(event, client) {
    var tmp = this.state.valAR2
    if(this.state.item.data.group) {
      tmp[client] = parseInt(event.target.value)
      if(event.target.value === '') {
        tmp[client] = 0
      }
    } else {
      tmp[0] = parseInt(event.target.value)
      if(event.target.value === '') {
        tmp[0] = 0
      }
    }
    
    this.setState({
      valAR2: tmp
    })
  }


  onChangeSimple(event, index) {
    var tmp = this.state.notesSimple
    tmp[index] = event
    this.setState({
      notesSimple: tmp
    })
  }


  onChangeSimpleValue(event, index) {
    var tmp = this.state.valueSimple
    tmp[index] = event.target.value
    this.setState({
      valueSimple: tmp
    })
  }


  updateScaled(value, index) {
    var tmp = this.state.scaledSimple
    tmp[index] = value
    this.setState({
      scaledSimple: tmp
    })
  }


  onChangeNotes(event) {
    this.setState({
      notes: event.target.value
    })
  }


  saveResults() {
    var block = this.state.program.blocks[this.state.results]
    var reps = []
    var repsr = []
    var weight = []
    var weightr = []

    // Simple results
    if(block.data.simple) {
      var tmp1 = this.state.valueSimple
      for(var t1 of tmp1) {
        if(t1 === '') {
          t1 = 0
        } else {
          t1 = parseFloat(t1)
        }
      }

      var notess = ''
      for(var note of this.state.notesSimple) {
        if(note !== '') {
          notess += '|'+note
        } else {
          notess += '|-'
        }
        notess = notess.substring(1)
      }

      if(this.state.item.data.group) {
        var times1 = []
        var amrap1 = []
        for(var i1=0; i1<this.state.item.data.clients.length; i1++) {
          if(block.data.type === 5) {
            var tt1 = 0
            if(this.state.valAR1[i1] !== '') {
              tt1 += parseInt(this.state.valAR1[i1])*60
            }
            if(this.state.valAR2[i1] !== '') {
              tt1 += parseInt(this.state.valAR2[i1])
            }
            times1.push(tt1)
          }
          if(block.data.type === 0) {
            var alabel = ''
            if(this.state.valAR1[i1] !== '') {
              alabel = this.state.valAR1[i1]+'+'
            } else {
              alabel = '0+'
            }
            if(this.state.valAR2[i1] !== '') {
              alabel += this.state.valAR2[i1]
            } else {
              alabel += '0'
            }
            amrap1.push(alabel)
          }
        }
        Firebase.database().ref('/sessions/'+global.uid+'/'+this.state.item.id+'/workout/'+this.state.program.id+'/blocks/'+block.id).update({
          valueSimple: tmp1,
          scaledSimple: this.state.scaledSimple,
          amrapSimple: amrap1,
          timeResGroup: times1,
          notesResSimple: notess
        })
      } else {
        var times2 = 0
        var amrap2 = []
        if(block.data.type === 5) {
          if(this.state.valAR1[0] !== '') {
            times2 += parseInt(this.state.valAR1[0])*60
          }
          if(this.state.valAR2[0] !== '') {
            times2 += parseInt(this.state.valAR2[0])
          }
        }
        if(block.data.type === 0) {
          var alabel2 = ''
          if(this.state.valAR1[0] !== '') {
            alabel2 = this.state.valAR1[0]+'+'
          } else {
            alabel2 = '0+'
          }
          if(this.state.valAR2[0] !== '') {
            alabel2 += this.state.valAR2[0]
          } else {
            alabel2 += '0'
          }
          amrap2.push(alabel2)
        }
        Firebase.database().ref('/sessions/'+global.uid+'/'+this.state.item.id+'/workout/'+this.state.program.id+'/blocks/'+block.id).update({
          valueSimple: tmp1,
          scaledSimple: this.state.scaledSimple,
          amrapSimple: amrap2,
          timeRes: times2,
          notesResSimple: notess
        })
      }
    // Standard results
    } else {
      // Group session
      if(this.state.item.data.group) {
        for(var ex of this.state.ex) {
          var labelr = ''
          var labelrr = ''
          var labelw = ''
          var labelwr = ''
          for(var i=0; i<this.state.item.data.clients.length; i++) {
            labelr += '-'+ex.reps[i]
            labelrr += ex.repsr[i]+'|'
            labelw += '-'+ex.weight[i]
            labelwr += ex.weightr[i]+'|'
          }
          labelr = labelr.substring(1)
          labelw = labelw.substring(1)
          reps.push(labelr)
          repsr.push(labelrr)
          weight.push(labelw)
          weightr.push(labelwr)
        }
        var time = []
        if(block.data.type === 5) {
          for(var i1=0; i1<this.state.item.data.clients.length; i1++) {
            var tt = 0
            if(this.state.valAR1[i1] !== '') {
              tt += parseInt(this.state.valAR1[i1])*60
            }
            if(this.state.valAR2[i1] !== '') {
              tt += parseInt(this.state.valAR2[i1])
            }
            time.push(tt)
          }
        }
        var notes = ''
        for(var note of this.state.notesSimple) {
          if(note !== '') {
            notes += '|'+note
          } else {
            notes += '|-'
          }
          notes = notes.substring(1)
        }
        Firebase.database().ref('/sessions/'+global.uid+'/'+this.state.item.id+'/workout/'+this.state.program.id+'/blocks/'+block.id).update({
          exResRepsGroup: reps,
          exResWeightGroup: weight,
          exResRepsRounds: repsr,
          exResWeightRounds: weightr,
          timeResGroup: time,
          notesRes: this.state.notes,
          notesResSimple: notes
        })
      // 1:1 session
      } else {
        var time2 = 0
        if(block.data.type === 5) {
          if(this.state.valAR1[0] !== '') {
            time2 += parseInt(this.state.valAR1[0])*60
          }
          if(this.state.valAR2[0] !== '') {
            time2 += parseInt(this.state.valAR2[0])
          }
        }
        for(var ex of this.state.ex) {
          reps.push(ex.reps[0])
          repsr.push(ex.repsr[0])
          weight.push(ex.weight[0])
          weightr.push(ex.weightr[0])
        }
        Firebase.database().ref('/sessions/'+global.uid+'/'+this.state.item.id+'/workout/'+this.state.program.id+'/blocks/'+block.id).update({
          exResReps: reps,
          exResWeight: weight,
          exResRepsRounds: repsr,
          exResWeightRounds: weightr,
          timeRes: time2,
          notesRes: this.state.notes,
          notesResSimple: this.state.notesSimple[0]
        })
      }
    }

    this.hideResults()
    EventEmitter.dispatch('showMessage', lang.t('messaging:message.resultsupdated'));
  }


  updateBest(ex) {
    var user = this.state.item.data.client
    for(var cl of global.clients) {
      if(cl.id === this.state.item.data.client) {
        user = cl.data.uid
      }
    }
    var update = true
    var final = 0
    var actual = 0
    var weight = 0
    var tmp = this.state.best
    var type = "kg"
    if(global.spaceLbs) {
        type = "lb"
    }
    if(ex.weightType !== "per") {
        type = ex.weightType
    }

    if(String(ex.resWeight).indexOf('-') !== -1) {
      var arr = ex.resWeight.split('-')
      for(var val of arr) {
        if(parseFloat(val) > weight) {
          weight = parseFloat(val)
        }
      }
      final = parseFloat(weight)
      actual = parseFloat(weight)
    } else {
      final = parseFloat(ex.resWeight)
      actual = parseFloat(ex.resWeight)
      weight = parseFloat(ex.resWeight)
    }
    
    var per = 0
    //for(var item of global.clientBest) {
    for(var item of tmp) {
      if(item.id === ex.id) {
        if(ex.tool !== 6 && ex.tool !== 7) {
          if(ex.weight > 0 && ex.weightType === 'per') {
            final = weight*(100/ex.weight)
            per = ex.weight
          }
          
          var rval = item.data.value
          var rtype = ""
          if(item.data.type !== undefined) {
            rtype = item.data.type
          }
          if((rtype === "kg" && ex.weightType === "lb") || (rtype === "kg" && ex.weightType === "per" && global.spaceLbs)) {
            rval = item.data.value*global.lbsUp
          }
          if((rtype === "lb" && ex.weightType === "kg") || (rtype === "lb" && ex.weightType === "per" && !global.spaceLbs)) {
            rval = item.data.value*global.lbsDown
          }
          if(rval > final) {
            update = false
          }
        }
        if(item.data.value < weight && (ex.tool === 6 || ex.tool === 7)) {
          update = false
        }
        if(update) {
          item.data.value = final
          this.setState({
            best: tmp
          })
        }
      }
    }

    if(final === 0) {
      update = false
    }

    var unit = ''
    if(ex.unit !== undefined) {
      unit = ex.unit
    }
    if(update && ex.tool !== 0 && ex.tool !== 9) {
      Firebase.database().ref('/records/'+user+'/'+ex.id).update({
        date: Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm'),
        id: ex.id,
        name: ex.name,
        tool: ex.tool,
        value: final,
        actual: actual,
        percent: per,
        unit: unit,
        type: type
      })
    }
  }


  updateBestGroup(pos, ex) {
    var update = true
    var final = 0
    var actual = 0
    var per = 0
    var weights = ex.resWeightGroup[pos]
    var arr1 = []
    var arr = []
    var tmp = this.state.bestGroup

    if(weights.indexOf('|') !== -1) {
      arr1 = weights.split('|')
      for(var v1 of arr1) {
        var max = 0
        var spl = v1.split('-')
        for(var vspl of spl) {
          if(parseFloat(vspl) > max) {
            max = parseFloat(vspl)
          }
        }
        arr.push(max)
      }
    } else {
      arr = weights.split('-')
      arr.splice(0,1)
    }
    
    for(var i=0; i<this.state.item.data.clients.length; i++) {
      update = true
      final = 0
      actual = 0
      per = 0
      var id = this.state.item.data.clients[i]
      for(var client of global.clients) {
        if(client.id === this.state.item.data.clients[i] && client.data.uid !== '') {
          id = client.data.uid
        }
      }
      if(ex.resWeightGroup !== undefined && ex.resWeightGroup.length > i && arr.length > i) {
        final = parseFloat(arr[i])
        actual = parseFloat(arr[i])
        if(ex.weight > 0 && ex.weightType === 'per') {
          final = parseFloat(arr[i])*(100/ex.weight)
          per = ex.weight
        }
      }

      if(ex.tool === 0 && ex.tool === 9) {
        update = false
      }
      for(var cb of tmp) {
        if(cb.client === id && cb.id === ex.id) {
          var rval = cb.data.value
          var rtype = ""
          if(cb.data.type !== undefined) {
            rtype = cb.data.type
          }
          if((rtype === "kg" && ex.weightType === "lb") || (rtype === "kg" && ex.weightType === "per" && global.spaceLbs)) {
            rval = cb.data.value*global.lbsUp
          }
          if((rtype === "lb" && ex.weightType === "kg") || (rtype === "lb" && ex.weightType === "per" && !global.spaceLbs)) {
            rval = cb.data.value*global.lbsDown
          }
          if(rval > final || rval === final) {
            update = false
          }

          if(update) {
            cb.data.value = final
            this.setState({
              bestGroup: tmp
            })
          }
        }
      }

      if(final === 0) {
        update = false
      }

      var unit = ''
      if(ex.unit !== undefined) {
        unit = ex.unit
      }

      if(update) {
        Firebase.database().ref('/records/'+id+'/'+ex.id).update({
          date: Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm'),
          id: ex.id,
          name: ex.name,
          tool: ex.tool,
          value: final,
          actual: actual,
          percent: per,
          unit: unit
        })
      }
    }
  }



  // Display stuff ------------------------------------------------------------



  renderExNotes(item, index) {
    if(item.data.exNotes !== undefined) {
      if(item.data.exNotes[index] !== undefined) {
        return (
          <span><br/>{item.data.exNotes[index]}</span>
        )
      }
    }
  }

  
  renderExBlock(block) {
    if(block.data.simple) {
      return (
        <div className="item pb-20" style={{whiteSpace: 'pre-wrap'}}><strong>{block.data.notes}</strong></div>
      )
    } else {
      return (
        <div>
          {block.data.exName.map((item, index) => (
          <div className="item mb-40" key={index}>
            <strong>{item}</strong><br/>{this.getExInfo(block, index)}{this.getInterval(block, index)}{this.renderExNotes(block, index)}
          </div>
          ))}
        </div>
      )
    }
  }


  renderExOpacity(index) {
    var opacity = 1
    var block = this.state.program.blocks[this.state.current].data
    if(block.type === 1 && !block.emom && index !== this.state.interval) {
      opacity = 0.4
    } else if(block.type === 2 && !block.emom && index !== this.state.interval) {
      opacity = 0.4
    } else if(block.type === 3) {

    }
    return opacity
  }


  renderBlockButton() {
    if(this.state.current === this.state.showing) {
      return (
        <div className="col-3 mt-30">
          <button className="btn primary" onClick={() => this.startTimer()}>Start</button>
        </div>
      )
    } else {
      if(this.state.program.blocks[this.state.showing].data.logResults !== false && this.state.showing < this.state.current) {
        return (
          <button className="btn tertiary" onClick={() => this.showResults(this.state.showing)}>Edit results</button>
        )
      }
    }
  }


  renderBlockNotes() {
    if(this.state.program.blocks[this.state.showing].data.notes !== '' && !this.state.program.blocks[this.state.showing].data.simple) {
      return (
        <div className="item mb-40" style={{whiteSpace: 'pre-wrap'}}><strong>Notes:</strong><br/>{this.state.program.blocks[this.state.showing].data.notes}</div>
      )
    }
  }


  renderCurrentBlockTitle() {
    let item = this.state.program.blocks[this.state.current]
    if(item.data.name !== '' && item.data.name !== undefined) {
      return item.data.name
    } else {
      return lang.t(global.exCats[item.data.cat])
    }
  }


  renderCurrent() {
    if(this.state.current < this.state.program.blocks.length) {
     return (
        <div className={this.state.current === this.state.showing ? 'item active' : 'item'} onClick={() => this.setState({showing: this.state.current})}>
          <div className="text lft">
            <span>{this.renderCurrentBlockTitle()}</span><br/>{this.getBlockHeader(this.state.program.blocks[this.state.current])}
          </div>
          <div className="status current rgt">
            <div className="inner"></div>
          </div>
          <div className="clear"></div>
        </div>
     )
    }
  }


  renderBlockTitle(item) {
    if(item.data.name !== '' && item.data.name !== undefined) {
      return item.data.name
    } else {
      return lang.t(global.exCats[item.data.cat])
    }
  }


  renderBlocks() {
    if(this.state.view === 'select' && this.state.item !== null) {
      var list1 = []
      var list2 = []
      for(var i=0; i<this.state.program.blocks.length; i++) {
        if(i<this.state.current) {
          list1.push(this.state.program.blocks[i])
        }
        if(i>this.state.current) {
          list2.push(this.state.program.blocks[i])
        }
      }
      return (
        <div>
          <div className="run-menubar">
            <div className="list">
              {list1.map((item, index) => (
                <div className={this.state.showing === index ? 'item active' : 'item'} key={item.id} onClick={() => this.setState({showing: index})}>
                  <div className="text lft">
                    <span>{this.renderBlockTitle(item)}</span><br/>{this.getBlockHeader(item)}
                  </div>
                  <div className="status done rgt">
                    <div className="inner"></div>
                  </div>
                  <div className="clear"></div>
                </div>
              ))}
              {this.renderCurrent()}
              {list2.map((item, index) => (
                <div className={this.state.showing === (this.state.current+index+1) ? 'item active' : 'item'} key={item.id} onClick={() => this.setState({showing: this.state.current+index+1})}>
                  <div className="text lft">
                    <span>{this.renderBlockTitle(item)}</span><br/>{this.getBlockHeader(item)}
                  </div>
                  <div className="status rgt"></div>
                  <div className="clear"></div>
                </div>
              ))}
            </div>
          </div>
          <div className="run-main">
            <h3 className="mt-30">{this.renderBlockTitle(this.state.program.blocks[this.state.showing])}</h3>
            <h4 className="mb-60">{this.getBlockHeader(this.state.program.blocks[this.state.showing])}</h4>
            {this.renderExBlock(this.state.program.blocks[this.state.showing])}
            {this.renderBlockNotes()}
            {this.renderBlockButton()}
          </div>
        </div>
      )
    }
  }


  renderTimer() {
    if(this.state.view === 'timer') {
      return (
        <div className="run-timer">
          <h3 className={this.state.timer}>{this.getTimerName()}</h3>
          <h2>{this.displayTimer()}</h2>
        </div>
      )
    }
  }


  renderSetsTitle() {
    if(this.state.program.blocks[this.state.current].data.rounds > 1) {
      return (
        <h3>Set {this.state.round}</h3>
      )
    }
  }


  renderSets() {
    if(this.state.view === 'sets') {
      if(this.state.program.blocks[this.state.current].data.simple) {
        return (
          <div>
            <div className="sets">
              {this.renderSetsTitle()}
              {this.state.program.blocks[this.state.current].data.exName.map((item, index) => (
                <div className="item" key={index} style={{whiteSpace: 'pre-wrap'}}>
                  {this.state.program.blocks[this.state.current].data.notes}
                </div>
              ))}
            </div>
            <div className="btn-sets">
              <button className="btn primary" onClick={() => this.finishSet()}>Finish set</button>
            </div>
          </div>
        )
      } else {
        return (
          <div>
            <div className="sets">
              {this.renderSetsTitle()}
              {this.state.program.blocks[this.state.current].data.exName.map((item, index) => (
                <div className="item" key={index}>
                  <strong>{item}</strong><br/>{this.getExInfo(this.state.program.blocks[this.state.current], index)}{this.renderExNotes(this.state.program.blocks[this.state.current], index)}
                </div>
              ))}
            </div>
            <div className="btn-sets">
              <button className="btn primary" onClick={() => this.finishSet()}>Finish set</button>
            </div>
          </div>
        )
      }
      
    }
  }


  renderScaled(index) {
    if(this.state.program.blocks[this.state.results].data.simple) {
      return (
        <InputToggle label={'Scaled'} value={this.state.scaledSimple[index]} onChange={(event) => this.updateScaled(event, index)} text={'Completed scaled'}/>
      )
    }
  }


  renderResults() {
    if(this.state.view === 'results') {
      if(this.state.item.data.group) {
        var list = this.state.item.data.clients
        return (
          <div>
            <div className="run-blocks">
              <div style={{height: '400px', width: this.state.item.data.clients.length*300, display: 'inline-block'}}>
                {list.map((item, index) => (
                  <div className="block result" key={index}>
                    <div className="client small static">
                      {HelperClients.getImage(this.getClient(item))}
                      <div className="text">{this.getClientName(item, 'first')} {this.getClientName(item, 'last')}</div> 
                    </div>
                    <div className="movements clear">
                      {this.renderExResult(index)}
                      <div className="item">
                        {this.renderScaled(index)}
                        <Textarea label={'Notes'} value={this.state.notesSimple[index]} onChange={(event) => this.onChangeSimple(event, index)}/>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="run-notes none">
              <h3>Notes</h3>
              <textarea value={this.state.notes} onChange={(val) => this.onChangeNotes(val)}/>
            </div>
            <div className="btn-sets">
              <button className="btn primary" onClick={() => this.saveResults()}>Save results</button>
              <div className="sv-30 clear"></div>
              <button className="btn tertiary width-12 close" onClick={() => this.hideResults()}>Cancel</button>
            </div>
          </div>
        )
      } else {
        return (
          <div>
            <div className="run-blocks">
              <div style={{height: '400px', width: 300, display: 'inline-block'}}>
                <div className="block result">
                  <div className="client small static">
                    {HelperClients.getImage(this.getClient(this.state.item.data.client))}
                    <div className="text">{this.getClientName(this.state.item.data.client, 'first')} {this.getClientName(this.state.item.data.client, 'last')}</div> 
                  </div>
                  <div className="movements clear">
                    {this.renderExResult(0)}
                    <div className="item">
                      {this.renderScaled(0)}
                      <Textarea label={'Notes'} value={this.state.notesSimple[0]} onChange={(event) => this.onChangeSimple(event, 0)}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="run-notes none">
              <h3>Notes</h3>
              <textarea value={this.state.notes} onChange={(val) => this.onChangeNotes(val)}/>
            </div>
            <div className="btn-sets">
              <button className="btn primary" onClick={() => this.saveResults()}>Save results</button>
              <div className="sv-30 clear"></div>
              <button className="btn tertiary width-12 close" onClick={() => this.hideResults()}>Cancel</button>
            </div>
          </div>
        )
      }
    }
  }

  
  renderList() {
    if(this.state.item !== null) {
      if(this.state.view === 'select') {
        if(this.state.item.data.group) {
          var list = this.state.item.data.clients
          return (
            <div className="run-sidebar">
              <h4 className="mb-20">Attending</h4>
              {list.map((item, index) => (
                <div className="list bare static" key={item}>
                  {HelperClients.getImage(this.getClient(item))}
                  <div className="main">
                    <h4>{this.getClientName(item, 'first')}</h4>
                    <p>{this.getClientName(item, 'last')}</p>
                  </div>
                  <div className="clear"></div>
                </div>
              ))}
            </div>
          )
        }
      } else if(this.state.view === 'timer' && this.state.timer !== 'getready' && this.state.program.blocks[this.state.current].data.type !== 4) {
        if(this.state.program.blocks[this.state.current].data.simple) {
          return (
            <div className="run-sidebar">
              <div className="list bare static">
                <h4 style={{whiteSpace: 'pre-wrap'}}>{this.state.program.blocks[this.state.current].data.notes}</h4>
              </div>
            </div>
          )
        } else {
          return (
            <div className="run-sidebar">
              <h4 className="mb-20">Movements</h4>
              {this.state.program.blocks[this.state.current].data.exName.map((item, index) => (
                <div className="list bare static" style={{opacity: this.renderExOpacity(index)}} key={index}>
                  <h4>{item}</h4>
                  <div className="main" style={{width: '100%', float: 'none'}}>
                    <p>{this.getExInfo(this.state.program.blocks[this.state.current], index)}{this.renderExNotes(this.state.program.blocks[this.state.current], index)}</p>
                    <div className="clear"></div>
                  </div>
                </div>
              ))}
            </div>
          )
        }
      }
    }
  }


  renderCurrentBlock() {
    if(this.state.view === 'timer') {
      var block = this.state.program.blocks[this.state.current].data
      if(block.type === 0 || block.type === 5) {
        return (
          <h2>{this.getBlockHeader(this.state.program.blocks[this.state.current])}</h2>
        )
      } else {
        if(block.type === 1) {
          if(block.rounds > 1) {
            return (
              <h2>EMOM ({HelperCal.getDurationMinAlt(parseInt(block.exWork[0]))}) - Round {this.state.round} of {block.rounds}</h2>
            )
          } else {
            return (
              <h2>EMOM ({HelperCal.getDurationMinAlt(parseInt(block.exWork[0]))})</h2>
            )
          }
        } else if(block.type === 2) {
          if(block.emom) {
            return (
              <h2>Intervals ({HelperCal.getDurationMinAlt(parseInt(block.exWork[0]))} / {HelperCal.getDurationMinAlt(parseInt(block.exRest[0]))}) - Round {this.state.round} of {block.rounds}</h2>
            )
          } else {
            return (
              <h2>Intervals - Round {this.state.round} of {block.rounds}</h2>
            )
          }
          
        } else if(block.type === 3) {
          if(block.rounds > 1) {
            return (
              <h2>Tabata - Round {this.state.round} of {block.rounds}</h2>
            )
          } else {
            return (
              <h2>{this.getBlockHeader(this.state.program.blocks[this.state.current])}</h2>
            )
          }
        }
      }
    } else if(this.state.view === 'sets') {
      return (
        <h2>{this.getBlockHeader(this.state.program.blocks[this.state.current])}</h2>
      )
    } else if(this.state.view === 'results') {
      return (
        <h2>Results for {this.getBlockHeader(this.state.program.blocks[this.state.results])}</h2>
      )
    }
  }


  renderButtonNotes() {
    if(this.state.program.blocks[this.state.current].data.notes !== '' && (!this.state.program.blocks[this.state.current].data.simple || this.state.program.blocks[this.state.current].data.simple === undefined)) {
      return (
        <button className="btn bnotes" onClick={() => this.showModalNotes()}></button>
      )
    }
  }


  renderButtons() {
    if(this.state.view === 'select' || this.state.view === 'results') {
      return (
        <div className="run-buttons">
          <button className={this.state.volume ? 'btn audio-on' : 'btn audio-off'} onClick={() => this.toggleAudio()}></button>
        </div>
      )
    } else if(this.state.view === 'sets') {
      return (
        <div className="run-buttons">
          <button className={this.state.volume ? 'btn audio-on' : 'btn audio-off'} onClick={() => this.toggleAudio()}></button>
          <button className="btn next" onClick={() => this.abortTimer()}></button>
          {this.renderButtonNotes()}
        </div>
      )
    } else {
      return (
        <div className="run-buttons">
          <button className={this.state.volume ? 'btn audio-on' : 'btn audio-off'} onClick={() => this.toggleAudio()}></button>
          <button className="btn next" onClick={() => this.abortTimer()}></button>
          <button className="btn pause" onClick={() => this.showModalPause()}></button>
          {this.renderButtonNotes()}
        </div>
      )
    }
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('assistant,assistant2,trainer')) {
      if(this.state.item !== null && this.state.program !== null) {
        return (
          <div className={'run theme-'+global.spaceTheme}>
            {this.renderBlocks()}
            {this.renderTimer()}
            {this.renderSets()}
            {this.renderResults()}
            {this.renderList()}
            <div className="header">
              <div className="content">
                <div className="back lft mr-10" onClick={() => this.endSession()}>
                  <div className="circle"></div>
                  <div className="arrow"></div>
                </div>
                {this.getSessionName()}
              </div>
            </div>
            {this.renderButtons()}
            <div className="run-blocktitle">
              {this.renderCurrentBlock()}
            </div>

            <Message/>
            {this.renderModalPause()}
            {this.renderModalAbort()}
            {this.renderModalEnergy()}
            {this.renderModalNotes()}
          </div>
        )
      } else {
        return (
          <div className={'run theme-'+global.spaceTheme}>
            <div className="header">
              <div className="content">
                <div className="back lft mr-10" onClick={() => this.endSession()}>
                  <div className="circle"></div>
                  <div className="arrow"></div>
                </div>
              </div>
            </div>
          </div>
        )
      }
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <div className="empty large pt-60 clear">
              <div className="illustration mb-30">
                <div className="img1"></div>
                <div className="img2"></div>
                <div className="img3"></div>
                <div className="img4"></div>
                {HelperEmptyImg.renderImage('programs')}
              </div>
              <div className="data">
                <h3 className="mb-20">Not available</h3>
                <p>You don't have access to running sessions.</p>
              </div>
            </div>
          </div>
          <div className="header">
            <div className="content">
              <h2>Session</h2>
            </div>
          </div>
          <Navigation active='clients' />
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['calendar','program','modals','header','common','messaging'])(withRouter(withAuthorization(condition)(ActiveSessionPage)));